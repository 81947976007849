
import { Component, Vue } from "vue-property-decorator";
import { TaskDurum } from "@/plugins/uyap-plugin/enum/TaskDurum";
import {
  ITask,
  ITasks,
  PlayerDurum,
} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import { ProgramPaths } from "@/enum/ProgramPaths";
import { Socket } from "vue-socket.io-extended";
import { TaskTuru } from "@/plugins/uyap-plugin/enum/TaskTuru";
import { TakipTalebiIndirCevap } from "@/plugins/uyap-plugin/uyap/TakipTalebiIndir";
import { saveAs } from "file-saver";
import { IDosyaEvrak } from "@/plugins/uyap-plugin/uyap/DosyaEvraklar";
import UyapTakipAc from "@/plugins/uyap-plugin/takip_ac/UyapTakipAc";
import JSZip from "jszip";
import app from "@/main";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipKopyalaForm from "@/components/forms/TakipKopyalaForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

@Component({
  components: { DeleteDialog, TakipKopyalaForm, FormDialog },
})
export default class TakipAcIslemDetayView extends Vue {
  @Socket("updateTasks")
  onUpdateTask() {
    this.load();
  }

  onFly = false;
  downloading = false;
  headers = [
    { text: "Esas No", value: "esasNo" },
    { text: "Birim Adı", value: "birimAdi" },
    { text: "Alacaklılar", value: "alacaklilar" },
    { text: "Borçlular", value: "borclular" },
    { text: "Takibe Esas Tutar", value: "toplamTakibeEsasMiktar" },
    { text: "Açıklama", value: "description" },
    { text: "Durum", value: "status" },
    { text: "İndir", value: "actions" },
  ];
  seciliHataliGorevler = [];
  items:any = [];
  loading = false;
  hataliHeaders = [
    { text: "Birim Adı", value: "birimAdi" },
    { text: "Alacaklılar", value: "alacaklilar" },
    { text: "Borçlular", value: "borclular" },
    { text: "Takibe Esas Tutar", value: "toplamTakibeEsasMiktar" },
    { text: "Açıklama", value: "description" },
    { text: "Durum", value: "status" },
  ];
  taskDurum = TaskDurum;
  programPaths = ProgramPaths;
  playerDurum = PlayerDurum;

  get isFirstTask(): boolean {
    return (
      this.$store.getters.getSiradakiGorevler[0]?.id == this.$route.params.id
    );
  }

  get paternTask(): ITask {
    let tasks: ITasks = this.$store.getters.getGorevler;
    let allTaskt: Array<ITask> = [
      ...tasks.incomplete_tasks,
      ...tasks.completed_tasks,
    ];
    return allTaskt.find(
      (task: ITask) => task.id == Number(this.$route.params.id)
    ) as ITask;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;
      this.items = (
        await this.$http.get(
          "/api/v1/task/" + this.$route.params.id + "/child-tasks"
        )
      ).data;
    } catch (e) {
      this.items = [];
    } finally {
      this.loading = false;
    }
  }


  async ttIndir(dosya: ITask) {
    let uyapTakip = UyapTakipAc.fromJson(dosya.data);
    await uyapTakip.takipTalebiIndir();
  }
  async vekaletnameIndir(dosya: ITask) {
    let uyapTakip = UyapTakipAc.fromJson(dosya.data);
    await uyapTakip.vekaletnameIndir();
  }
  async tahsilHarciMakbuzuIndir(dosya: ITask) {
    let uyapTakip = UyapTakipAc.fromJson(dosya.data);
    await uyapTakip.tahsilHarciMakbuzuIndir();
  }
  async vekaletPuluIndir(dosya: ITask) {
    let uyapTakip = UyapTakipAc.fromJson(dosya.data);
    await uyapTakip.vekaletPuluIndir();
  }
  async downloadAll(tur: string) {
    try{
      this.loading = true;
      let zip = new JSZip();
      let successItemCount = 0;
      for (let item of this.items) {
        if(item.status == this.taskDurum.BITTI_BASARILI){
          try{
            let uyapTakip = UyapTakipAc.fromJson(item.data);
            let blob: Blob = await uyapTakip.getEvrakBlobFromType(tur);
            //@ts-ignore
            zip.file(app.$uyapHelper.seoUrl(blob.name), blob);
            successItemCount ++;
            this.$toast.success(uyapTakip.takipBilgileri.dosyaEsasNo + " " + tur + " başarılı.");
          }catch (e) {
            this.$toast.error("Evrak indirilemedi ! HATA : " + e.message);
          }
        }
      }
      if(successItemCount > 0){
        zip.generateAsync({ type: "blob" }).then((content:any) => {
          // see FileSaver.js
          saveAs(content, tur + "_toplu.zip");
        });
      }else{
        this.$toast.error("İndirilebilir evrak bulunamadı !");
      }

    }catch (e) {
      this.$toast.error(e.message);
    }finally {
      this.loading = false;
    }

  }

  downloadExcel() {
    this.downloading = true;
    this.$helper
      .download(
        "Excel Sonuçlar.xlsx",
        "/api/v1/export-to-excel/" + this.$route.params.id
      )
      .finally(() => {
        this.downloading = false;
      });
  }

  async durdurDevamEt() {
    if (this.$store.getters.getPlayerDurum == this.playerDurum.oto) {
      this.$socket.client.emit("player", this.playerDurum.durduruluyor);
    } else {
      this.$socket.client.emit("player", this.playerDurum.oto);
    }
  }

  selectAllToggle() {
    let hatalilar = this.items.filter(
      (item: ITask) =>
        item.status == this.taskDurum.BITTI_HATALI ||
        item.status == this.taskDurum.BITTI_EKSIK
    );
    if (this.seciliHataliGorevler.length == hatalilar.length) {
      this.seciliHataliGorevler = [];
    } else {
      this.seciliHataliGorevler = hatalilar;
    }
  }

  async tryAgainCreateTask() {
    if (this.seciliHataliGorevler.length > 0) {
      this.onFly = true;
      let tasks: Array<ITask> = [];
      this.seciliHataliGorevler.forEach((item: any) => {
        let task: ITask = { ...item };
        task.id = null;
        task.status = TaskDurum.SIRADA;
        task.response = null;
        task.parent_id = null;
        tasks.push(task);
      });
      if (tasks[0].task_type_id == TaskTuru.takip_ac) {
        let takiplerEsasNoYok = this.seciliHataliGorevler
          .map((takip: ITask) => takip.birim_adi)
          .join(", ");
        tasks[0].aciklama =
          takiplerEsasNoYok + " hatalı takiplerin yeniden açılması.";
      } else {
        tasks[0].aciklama =
          "Hatalı hızlı takip açma işlemlerinin tekrarlanması.";
      }
      try {
        await this.$store.dispatch("postTasks", tasks);
        let req = {
          ids: this.seciliHataliGorevler.map((gorev: ITask) => gorev.id),
          status: TaskDurum.YENIDEN_BASLATILDI,
        };
        await this.$http.post("/api/v1/task/update-status", req);
        await this.$store.dispatch("getTasksFromServer");
        await this.load();
        await this.$router.push(ProgramPaths.eicrapro + "/islemler");
      } catch (e) {
        console.error(e);
      } finally {
        this.seciliHataliGorevler = [];
        this.onFly = false;
      }
    } else {
      this.$toast.info("Yeniden başalatılacak görevleri seçiniz.");
    }
  }
}
